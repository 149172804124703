<template>
  <div class="table-box">
        <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
       opt: {
        search: [],
        columns: [
          { label: "密码名称", key: "keyboardPwdName",},
          { label: "密码", key: "keyboardPwd" },
          { label: "密码时效", key: "pwTimeEffectStr" },
          { label: "生效/时效时间", key: "takeEffectTimeStr",width: 380  },
          { label: "密码状态", key: "statusStr" },
          { label: "获取人", key: "senderUsername" },
          {
            label: "获取人角色",
            key: "roleStr"
          },
          {
            label: "创建时间",
            key: "createTime"
          }
        ]
      },
      roomList: [],
      userInfo:{}
    };
  },
  created() {
    console.log("passwordInformation created!!");
    this.userInfo = this.cache.getLS("userInfo");
    this.row = this.cache.get("lockInfo");
  },
  activated() {
    console.log("passwordInformation activated!!");
    this.row = this.cache.get("lockInfo");
    // this.$refs.appList.onRefresh();
  },
  mounted(){
     
  },
  methods: {
    onGet(opt) {
      console.log(opt.searchForm);
      let dto = {
        pageNo: opt.skip,
        pageSize: opt.limit,
        roomId: this.row.roomId,
        historyId: this.$route.params.id,
        roleType: 0,
        type: 'password',
        // beginTime: opt.searchForm["date"] ? opt.searchForm["date"][0] : "",
        // endTime: opt.searchForm["date"] ? opt.searchForm["date"][1] : "",
        // unLockMethod: opt.searchForm["unLockMethod"]
        //   ? opt.searchForm["unLockMethod"]
        //   : null
      };
      if(this.userInfo["roleType"]=='2' || this.userInfo["roleType"]=='6'){
        dto.plainUserName=this.userInfo["phoneNum"];
        dto.userId=this.userInfo["userId"];
      }
      this.post("landlord-service/house/v1/device/additive/history/page", dto, {
        isUseResponse: true
      }).then(res => {
        res = JSON.parse(JSON.stringify(res).replace(/list/g, "data"));
        if (res.data.data.data) {
          for (let i = 0; i < res.data.data.data.length; i++) {
            let item = res.data.data.data[i];
            if (item.attachment) {
              item.attachment = JSON.parse(item.attachment)
              item.keyboardPwdName = item.attachment.keyboardPwdName
              if(item.attachment.roleId == 5) {
                item.keyboardPwd = item.attachment.keyboardPwd ? '*******' : '--';
              }else {
                item.keyboardPwd = item.attachment.keyboardPwd ? item.attachment.keyboardPwd : '--';
              }

              item.pwTimeEffectStr = ['单次','永久','限时','清空码'][item.attachment.keyboardPwdType-1];
              if(moment(item.beginTime).add(49, 'years').isBefore(item.endTime)){
                item.pwTimeEffectStr='永久';
              }
              item.statusStr = ['正常','','待生效','添加中','添加失败','修改中','修改失败','删除中','删除失败'][item.attachment.status-1];
              if(item.endTime&& item.attachment.keyboardPwdType != 2) {//非永久
                if(moment(item.endTime).format('YYYY-MM-DD HH:mm:ss') < moment().format('YYYY-MM-DD HH:mm:ss')) {
                  item.statusStr = '已失效';
                }
              }
              item.roleStr = ['','系统管理员','房东','工程人员','财务','房客','房管员','','公安'][item.attachment.roleId]
            }
            let _startDate = item.beginTime;
            let _endDate = item.endTime;
            if ( item.attachment.keyboardPwdType == 2 || moment(_startDate).add(49, 'years').isBefore(_endDate)) {
              item.takeEffectTimeStr = '--';
            }
            else {
              item.takeEffectTimeStr = this.format(_startDate)+' ~ '+this.format(_endDate);
            }
          }
        } else {
          res.data.data.data = []
        }
        opt.cb(res.data.data);
      });
    },
  }
};
</script>
<style lang="scss">
.table-box{
  height: 620px;
}
</style>