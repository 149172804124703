<template>
  <div class="table-box">
        <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
       opt: {
        search: [],
        columns: [
          { label: "接受者", key: "phoneStr",},
          { label: "接受者姓名", key: "keyName" },
          { label: "钥匙时效", key: "keyTimeEffectStr" },
          { label: "生效/时效时间", key: "takeEffectTimeStr",width: 380  },
          { label: "钥匙状态", key: "statusStr" },
          { label: "发钥匙人", key: "senderUsername" },
          {
            label: "发钥匙人角色",
            key: "roleStr"
          },
          {
            label: "发钥匙时间",
            key: "createTime"
          }
        ]
      },
      roomList: [],
      userInfo:{}
    };
  },
  created() {
    console.log("share created!!");
    this.userInfo = this.cache.getLS("userInfo");
    this.row = this.cache.get("lockInfo");
  },
  activated() {
    console.log("passwordInformation activated!!");
    this.row = this.cache.get("lockInfo");
    // this.$refs.appList.onRefresh();
  },
  mounted(){
     
  },
  methods: {
    onGet(opt) {
      console.log(opt.searchForm);
      let dto = {
        pageNo: opt.skip,
        pageSize: opt.limit,
        roomId: this.row.roomId,
        historyId: this.$route.params.id,
        type:'blue-key'
        // beginTime: opt.searchForm["date"] ? opt.searchForm["date"][0] : "",
        // endTime: opt.searchForm["date"] ? opt.searchForm["date"][1] : "",
        // unLockMethod: opt.searchForm["unLockMethod"]
        //   ? opt.searchForm["unLockMethod"]
        //   : null
      };
      console.log(this.userInfo["roleType"]);
       if(this.userInfo["roleType"]=='2' || this.userInfo["roleType"]=='6'){
        dto.plainUserName=this.userInfo["phoneNum"];
        dto.userId=this.userInfo["userId"];
      }
      this.post("landlord-service/house/v1/device/additive/history/page", dto, {
        isUseResponse: true
      }).then(res => {
        res = JSON.parse(JSON.stringify(res).replace(/list/g, "data"));
        if (res.data.data.data) {
          for (let i = 0; i < res.data.data.data.length; i++) {
            let item = res.data.data.data[i];
            if (item.attachment) {
              item.attachment = JSON.parse(item.attachment)
              item.phoneStr = item.attachment.phone ? item.attachment.phone.substring(0, 3) + "****" +
              item.attachment.phone.substring(7, item.attachment.phone.length) : "";
              item.keyName = item.attachment.keyName;
              let _startDate = item.beginTime;
              let _endDate = item.endTime;
              if(moment(_startDate).add(49, 'years').isBefore(_endDate) || item.endTime == 0 || item.endTime == null){
                item.keyTimeEffectStr='永久';
                item.takeEffectTimeStr='--';
              }
              else{
                item.keyTimeEffectStr = '限时';
                item.takeEffectTimeStr = this.format(_startDate) + ' ~ ' + this.format(_endDate);
              }
              switch(item.attachment.keyStatus){
                case '110401':
                  item.statusStr='正常使用';
                  break;
                case '110402':
                  item.statusStr='待接收';
                  break;
                case '110405':
                  item.statusStr='已冻结';
                  break;
                case '110408':
                  item.statusStr='已删除';
                  break;
                case '110410':
                  item.statusStr='已重置';
                  break;
              }
              if(item.attachment.keyStatus == '110401'){
                let timeout = moment().format('x') > moment(item.endTime).format('x') ? 0 : 1
                item.statusStr = ['已失效','有效'][timeout]
              }
              item.roleStr=['','系统管理员','房东','工程人员','财务','房客','房管员','','公安'][item.attachment.roleId]
            }
          }
        } else {
          res.data.data.data = []
        }
        opt.cb(res.data.data);
      });
    },
  }
};
</script>
<style lang="scss">
.table-box{
  height: 620px;
}
</style>