<template>
    <div class="table-box">
        <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
    </div>
</template>
<script>
    export default {
        data() {
            let _this = this;
            return {
                opt: {
                    search: [],
                    columns: [
                        {
                            label: "指纹名称",
                            key: "name",
                        },
                        {
                            label: "指纹类型",
                            key: "fingerTypeStr"
                        },
                        {
                            label: "生效/时效时间",
                            key: "pwTimeEffectStr",
                            width: 380
                        },
                        {
                            label: "指纹状态",
                            key: "fingerState"
                        },
                        {
                            label: "添加人",
                            key: "senderUsername"
                        },
                        {
                            label: "添加人角色",
                            key: "roleNameStr"
                        },
                        {
                            label: "添加时间",
                            key: "createTime"
                        },
                    ]
                },
                roomList: [],
                userInfo: {}
            };
        },
        created() {
            this.userInfo = this.cache.getLS("userInfo");
            this.row = this.cache.get("lockInfo");
        },
        activated() {
            this.row = this.cache.get("lockInfo");
        },
        mounted() {

        },
        methods: {
            onGet(opt) {
                let dto = {
                    roomId: this.row.roomId,
                    pageNo: opt.skip,
                    pageSize: opt.limit,
                    historyId: this.$route.params.id,
                    roleType: 0,
                    type: 'finger',
                    ownerType: this.userInfo["roleType"] == '1' ?  null : 'landlord'
                };
                if (this.userInfo["roleType"] == '2' || this.userInfo["roleType"] == '6') {
                    dto.plainUserName = this.userInfo["phoneNum"];
                    dto.userId = this.userInfo["userId"];
                }
                this.post("landlord-service/house/v1/device/additive/history/page", dto, {
                    isUseResponse: true
                }).then(res => {
                    res = JSON.parse(JSON.stringify(res).replace(/list/g, "data"));
                    if (res.data.data.data) {
                        for (let i = 0; i < res.data.data.data.length; i++) {
                            let item = res.data.data.data[i];
                            item.name = item.name ? item.name : '--' ;
                            let _startDate = item.beginTime;
                            let _endDate = item.endTime;
                            if (item.validTimes == 1) {
                                item.pwTimeEffectStr = '--' ;
                                item.fingerTypeStr = '单次';
                            } else {
                                if(moment(_startDate).add(49, 'years').isBefore(_endDate) || item.endTime == 0 || item.endTime == null){
                                    item.pwTimeEffectStr = '--';
                                    item.fingerTypeStr = '永久';
                                }
                                else{
                                    item.pwTimeEffectStr = this.format(_startDate)+ ' ~ ' +this.format(_endDate);
                                    item.fingerTypeStr = '限时';
                                }
                            }
                            item.roleNameStr = ['', '系统管理员', '房东', '工程人员', '财务', '房客', '房管员', '', '公安'][item.roleName]
                            item.fingerState = ['有效','已失效','待生效','添加中','添加失败','修改中','修改失败','删除中','删除失败'][item.state - 1]
                            if(item.state == 1 || item.state == 2) {
                                let timeout = moment().format('x') > moment(item.endTime).format('x') ? 0 : 1
                                item.fingerState = ['已失效','有效'][timeout]
                            }
                        }
                    }else {
                        res.data.data.data = []
                    }
                    opt.cb(res.data.data);
                });
            },
        }
    };
</script>
<style lang="scss">
    .table-box {
        height: 620px;
    }
</style>